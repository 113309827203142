import React, { useEffect, useState } from 'react';
import OurPackages from '../landingPageSections/packagesSection/OurPackages';
import "./OurPackagesPageStyles.css"
import useTitleAndOgTitleMetaTag from '../UseTitleAndOgTitleMetaTag';
import SiteMetaPropertyEnum from '../../data/enums/SiteMetaPropertiesEnum';
import UseDescriptionAndOgDescriptionMetaTag from '../UseDescriptionAndOgDescriptionMetaTag';
import UseOgUrlMetaTag from '../UseOgUrlMetaTag';
import UseOgTypeMetaTag from '../UseOgTypeMetaTag';
import UseCanonicalLinkTag from '../UseCanonicalLinkTag';
import { Link } from 'react-router-dom';


const OurPackagesPage: React.FC = () => {

    useTitleAndOgTitleMetaTag(SiteMetaPropertyEnum.OurPackagesPageSiteTitle, SiteMetaPropertyEnum.OurPackagesPageSiteTitle);

    UseDescriptionAndOgDescriptionMetaTag(SiteMetaPropertyEnum.OurPackagesPageSiteDescription, SiteMetaPropertyEnum.OurPackagesPageSiteDescription);

    UseOgUrlMetaTag(SiteMetaPropertyEnum.URLOurPackagesSite);
    UseOgTypeMetaTag(SiteMetaPropertyEnum.OgTypeWebsite);
    UseCanonicalLinkTag(SiteMetaPropertyEnum.URLOurPackagesSite)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='our-packages-page-container'>
            <p className='our-packages-page-section-text'>{SiteMetaPropertyEnum.OurPackagesPageSiteDescription}.</p>
            <p className='our-packages-page-section-text'>Я также могу помочь Вам в рамках <Link to="/my-services/">разовых сопровождений</Link>.</p>
            <OurPackages />
        </div>
    );
};

export default OurPackagesPage;
