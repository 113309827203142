import React from 'react';
import './ContactMeStyles.css';
import ContactDetailEnum from '../../../data/enums/ContactDetailsEnum';
import { ReactComponent as InstagramSVG } from '../../../assets/instagram.svg';
import { ReactComponent as WhatsappSVG } from '../../../assets/whatsapp.svg';
import { ReactComponent as TelegramSVG } from '../../../assets/telegram.svg';
import ReactGA from 'react-ga4';
import ScrollAnimation from '../../ScrollAnimation';
import YandexMetrikaEnums from '../../../data/enums/YandexMetrikaEnum';

const ContactMe: React.FC = () => {
    return (
        <section className='contact-me-section'>
            <h2 className='contact-me-section-heading-text'>Контакты</h2>
            <ScrollAnimation direction={'left'} displayedThreshold={0.3} customClassName='replace-slide-animation'>
                <div className='contact-me-section-text'>Свяжитесь со мной удобным Вам способом</div>
            </ScrollAnimation>
            <div className='contact-me-social-media-container'>
                <ScrollAnimation direction={'right'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                    <a href={ContactDetailEnum.Instagram} target="_blank" rel="noopener noreferrer" onClick={() => {
                        ReactGA.event({
                            category: 'User',
                            action: `Instagram -> Контакты`
                        });
                        ym(YandexMetrikaEnums.id, 'reachGoal', 'Instagram');
                    }}>
                        <InstagramSVG className="contact-me-social-icon contact-social-button-with-pulse-animation" />
                    </a>
                </ScrollAnimation>

                <ScrollAnimation direction={'down'} displayedThreshold={0.5} customClassName='replace-slide-animation'>
                    <a href={ContactDetailEnum.WhatsApp} target="_blank" rel="noopener noreferrer" onClick={() => {
                        ReactGA.event({
                            category: 'User',
                            action: `WhatsApp -> Контакты`
                        });
                        ym(YandexMetrikaEnums.id, 'reachGoal', 'WhatsApp')
                    }}>
                        <WhatsappSVG className="contact-me-social-icon contact-social-button-with-pulse-animation" />
                    </a>
                </ScrollAnimation>

                <ScrollAnimation direction={'left'} displayedThreshold={0.6} customClassName='replace-slide-animation'>
                    <a href={ContactDetailEnum.Telegram} target="_blank" rel="noopener noreferrer" onClick={() => {
                        ReactGA.event({
                            category: 'User',
                            action: `Telegram -> Контакты`
                        });
                        ym(YandexMetrikaEnums.id, 'reachGoal', 'Telegram')
                    }}>
                        <TelegramSVG className="contact-me-social-icon contact-social-button-with-pulse-animation" />
                    </a>
                </ScrollAnimation>
            </div>
        </section>
    );
};

export default ContactMe;