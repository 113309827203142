import React, { useEffect, useRef, useState } from 'react';
import './PackageOverviewStyles.css';
import { FaStar } from "react-icons/fa";
import PackageInformationStandard from './PackageInformationStandard';
import PackageInformationPremium from './PackageInformationPremium';
import PackageInformationVip from './PackageInformationVip';
import ContactDetailEnum from '../../../data/enums/ContactDetailsEnum';
import { ReactComponent as InstagramSVG } from '../../../assets/instagram.svg';
import { ReactComponent as WhatsappSVG } from '../../../assets/whatsapp.svg';
import { ReactComponent as TelegramSVG } from '../../../assets/telegram.svg';
import ImageSpinnerWrapper from '../../CustomImageWithSpinner';
import ReactGA from 'react-ga4';
import prcValues from '../../../data/enums/prcValues';
import PackageInformationAlreadyHere from './PackageInformationAlreadyHere';
import YandexMetrikaEnums from '../../../data/enums/YandexMetrikaEnum';
import PackageInformationJustBirth from './PackageInformationJustBirth';
import PackageInformationBeforeBirth from './PackageInformationBeforeBirth';
import PackageInformationAfterBirth from './PackageInformationAfterBirth';

interface PackageOverviewProps {
    type: 'already-here' | 'standard' | 'premium' | 'vip' | 'mini-birth-before' | 'mini-birth-after' | 'mini-just-birth';
}

const PackageOverview: React.FC<PackageOverviewProps> = ({ type }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef(null as any);
    const [contentHeight, setContentHeight] = useState(0);
    const [heightDataFromChild, setHeightDataFromChild] = useState<number>(0);
    const [isExpandedDataFromChild, setIsExpandedDataFromChild] = useState<boolean>(false);
    const [isPrevExpandedDataFromChild, setIsPrevExpandedDataFromChild] = useState<boolean>(true);
    const [priceValue, setPriceValue] = useState<string | null>(null);
    const [isPriceButtonClicked, setIsPriceButtonClicked] = useState<boolean>(false);

    const fetchPriceValue = () => {
        let index: number;
        if (type === "standard") {
            index = 0;
        } else if (type === 'premium') {
            index = 1;
        } else if (type === 'already-here') {
            index = 3;
        } else if (type === 'mini-just-birth') {
            index = 5;
        } else if (type === 'mini-birth-before') {
            index = 6;
        } else if (type === 'mini-birth-after') {
            index = 7;
        }
        else {
            index = 2;
        }
        if (index >= 0 && index < prcValues.length) {
            setPriceValue(prcValues[index]);
        } else {
            setPriceValue('Ошибка - индекс за рамками');
        }
    };

    const toggleDetails = (type: 'already-here' | 'standard' | 'premium' | 'vip' | 'mini-birth-before' | 'mini-birth-after' | 'mini-just-birth') => {
        ReactGA.event({
            category: 'User',
            action: `Пакет ${type} - ${(isExpanded ? "Свернуть" : "Раскрыть")} -> Подробнее `
        });
        if (isExpanded) {
            ym(YandexMetrikaEnums.id, 'reachGoal', `info-${type}`);
        }
        setIsExpanded(prev => !isExpanded);
        setIsPrevExpandedDataFromChild(prevVal => !isPrevExpandedDataFromChild);
        if (isExpandedDataFromChild) {
            setIsExpandedDataFromChild(false)
        } else {
            setIsExpandedDataFromChild(false)
        }
    };

    const measureContentHeight = () => {
        setContentHeight(contentRef.current.scrollHeight);
    };

    // Measure the content height on component mount and when isCollapsed changes
    useEffect(() => {
        measureContentHeight();
    }, [isExpanded]);

    // Update content height when content changes
    useEffect(() => {
        measureContentHeight();
    }, [contentRef.current]);

    useEffect(() => {

    }, [heightDataFromChild]);

    useEffect(() => {
        if (heightDataFromChild < contentHeight) {
            setContentHeight(contentHeight - heightDataFromChild)
        }
        // if (isExpanded && isExpandedDataFromChild) {
        //     // setContentHeight(contentHeight)
        // } else if (isExpanded && !isExpandedDataFromChild) {
        //     setContentHeight(contentHeight - heightDataFromChild)
        // }
    }, [isExpandedDataFromChild]);

    const receiveHeightDataFromChild = (data: number) => {
        setHeightDataFromChild(data);
    };

    const receiveIsExpandedDataFromChild = (data: boolean) => {
        setIsExpandedDataFromChild(data);
    };

    return (
        <div className={`packageOverview container-${type}-package ${isExpanded ? 'open' : ''}`}>
            <div className={`packageNameHeader ${type}`}>
                {/* {type === 'premium'
                    ? 'ПАКЕТ ПРЕМИУМ'
                    : type === 'vip'
                        ? 'ПАКЕТ VIP'
                        : type === 'already-here'
                            ? 'ПАКЕТ "ВСЕ НА МЕСТЕ"'
                            : 'ПАКЕТ СТАНДАРТ'} */}
                {type === 'already-here'
                    ? 'ПАКЕТ "ВСЕ НА МЕСТЕ"'
                    : type === "standard"
                        ? 'ПАКЕТ "СТАНДАРТ"'
                        : type === "mini-just-birth"
                            ? 'ПАКЕТ "ТОЛЬКО РОДЫ"'
                            : type === "mini-birth-before"
                                ? 'ПАКЕТ "ДО И РОДЫ"'
                                : 'ПАКЕТ "РОДЫ И ПОСЛЕ"'}

                <FaStar className={`packageHeaderIcon icon-${type}`} />
            </div>
            <div className={`${type}PackageShortDescription package-short-description`}>

                {type === 'standard' && <div>
                    Все необходимое для комфортных родов - полное сопровождение
                </div>
                }
                {type === 'already-here' && <div>
                    Заботливое сопровождение для тех, кто уже находится в Аргентине
                </div>
                }
                {type === 'mini-just-birth' && <div>
                    Мини пакет - только сопровождение на родах
                </div>
                }
                {type === 'mini-birth-before' && <div>
                    Мини пакет - только самое нужное до родов и сопровождение на родах
                </div>
                }
                {type === 'mini-birth-after' && <div>
                    Мини пакет - сопровождение на родах и только самое нужное после родов
                </div>
                }
                {type === 'premium' && <div>
                    Комфортные роды для тех, кто планирует легализоваться в Аргентине
                </div>
                }
                {type === 'vip' && <div>
                    Возьмем на себя все Ваши заботы для комфортных родов
                </div>
                }
            </div>
            <div>
                {type === 'mini-just-birth' &&
                    <ImageSpinnerWrapper immediateLoad={true}>
                        <img src={require('../../../assets/temp/temp_logo_cropped.png')} className='packageImage mini-just-birth-package-logo-filtered-color'
                            alt='just birth package logo' />
                    </ImageSpinnerWrapper >}
                {type === 'mini-birth-before' &&
                    <ImageSpinnerWrapper immediateLoad={true}>
                        <img src={require('../../../assets/temp/temp_logo_cropped.png')} className='packageImage mini-birth-before-package-logo-filtered-color'
                            alt='birth before package logo' />
                    </ImageSpinnerWrapper >}
                {type === 'mini-birth-after' &&
                    <ImageSpinnerWrapper immediateLoad={true}>
                        <img src={require('../../../assets/temp/temp_logo_cropped.png')} className='packageImage mini-birth-after-package-logo-filtered-color'
                            alt='birth after package logo' />
                    </ImageSpinnerWrapper >}
                {type === 'already-here' &&
                    <ImageSpinnerWrapper immediateLoad={true}>
                        <img src={require('../../../assets/temp/temp_logo_cropped.png')} className='packageImage already-here-package-logo-filtered-color'
                            alt='standard package logo' />
                    </ImageSpinnerWrapper >}
                {type === 'standard' &&
                    <ImageSpinnerWrapper immediateLoad={true}>
                        <img src={require('../../../assets/temp/temp_logo_cropped.png')} className='packageImage standard-package-logo-filtered-color'
                            alt='standard package logo' />
                    </ImageSpinnerWrapper>}
                {type === 'premium' &&
                    <ImageSpinnerWrapper immediateLoad={true}>
                        <img src={require('../../../assets/temp/temp_logo_cropped.png')} className='packageImage premium-package-logo-filtered-color'
                            alt='premium package logo' />
                    </ImageSpinnerWrapper>}
                {type === 'vip' &&
                    <ImageSpinnerWrapper immediateLoad={true}>
                        <img src={require('../../../assets/temp/temp_logo_cropped.png')} className='packageImage vip-package-logo-filtered-color'
                            alt='vip package logo' />
                    </ImageSpinnerWrapper>}</div>
            <div>
                <button className={`toggleDetails ${type}ToggleDetails`} onClick={() => {
                    toggleDetails(type)
                    setIsPriceButtonClicked(false);
                }}>
                    {isExpanded ? 'Свернуть' : 'Подробнее'}
                    {/* {isExpanded ? <BiCollapse /> : <IoExpand />} */}
                </button>
                {/* <div>isPrev from child: {isPrevExpandedDataFromChild ? "true" : "false"}</div>
                <div>from child: {isExpandedDataFromChild ? "true" : "false"}</div>
                <div>parent content height {contentHeight}</div>
                <div>heightDataFromChild {heightDataFromChild}</div> */}
                <div className={`details ${isExpanded ? 'open' : 'closed'}`} style={{
                    height:
                        !isExpanded ?
                            "0px" :
                            isExpandedDataFromChild ?
                                `${contentHeight + heightDataFromChild}px` :
                                `${contentHeight}px`
                }}
                    ref={contentRef}>
                    {type === 'mini-just-birth' &&
                        <PackageInformationJustBirth />
                    }
                    {type === 'mini-birth-before' &&
                        <PackageInformationBeforeBirth />
                    }
                    {type === 'mini-birth-after' &&
                        <PackageInformationAfterBirth />
                    }
                    {type === 'already-here' &&
                        <PackageInformationAlreadyHere />
                    }
                    {type === 'standard' &&
                        <PackageInformationStandard isEmbeddedInPremium={false} />
                    }
                    {type === 'premium' &&
                        <PackageInformationPremium
                            sendHeightDataToParent={receiveHeightDataFromChild} sendIsExpandedDataToParent={receiveIsExpandedDataFromChild} />
                    }
                    {type === 'vip' &&
                        <PackageInformationVip
                            sendHeightDataToParent={receiveHeightDataFromChild} sendIsExpandedDataToParent={receiveIsExpandedDataFromChild} />
                    }

                    {type !== 'mini-just-birth' && type !== 'mini-birth-after' &&
                        <p style={{ fontSize: "13px", marginBottom: "0px", padding: "0px 10px", textAlign: "left" }}><hr /><strong>*</strong> <strong>Цена</strong> ниже указана для сопровождения с <strong>30 недели</strong> беременности. Для более ранних сроков цена обговаривается отдельно</p>}
                    <div className={`packagePrice ${type}-package-price`}>
                        {!isPriceButtonClicked && <div onClick={() => {
                            fetchPriceValue();
                            setIsPriceButtonClicked(true);
                            ReactGA.event({
                                category: 'User',
                                action: `Цена пакета ${type}`
                            });
                            ym(YandexMetrikaEnums.id, 'reachGoal', `info-${type}-price`);
                        }}
                            className={`price-value-button price-with-pulse-animation ${type}ToggleDetails`}>Узнать цену</div>}
                        {isPriceButtonClicked && <div className='price-value'
                        // style={{ marginTop: type === "standard" ? "-20px" : "inherit", marginBottom: type === "standard" ? "5px" : "inherit", paddingBottom: type === "standard" ? "0px" : "inherit" }}
                        >
                            {/* {(type === "standard") && <span style={{ textDecorationLine: 'line-through', fontSize: "15px" }}>{priceValue}$<br /></span>} */}
                            {(type === "standard") && <span>{prcValues[4]}$</span>}
                            {(type !== "standard") && <span>{priceValue}$</span>}
                            {type !== 'mini-just-birth' && type !== 'mini-birth-after' && <span>*</span>}
                        </div>}
                        Задать вопрос
                        <div className='package-section-contact-me-social-media-container'>
                            <a href={ContactDetailEnum.Instagram} target="_blank" rel="noopener noreferrer">
                                <InstagramSVG className="package-section-contact-me-social-icon package-social-button-with-pulse-animation"
                                    onClick={() => {
                                        ReactGA.event({
                                            category: 'User',
                                            action: `Instagram -> Пакет ${type}`
                                        });
                                        ym(YandexMetrikaEnums.id, 'reachGoal', 'Instagram');
                                    }} />
                            </a>
                            <a href={ContactDetailEnum.WhatsApp} target="_blank" rel="noopener noreferrer"
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'User',
                                        action: `WhatsApp -> Пакет ${type}`
                                    });
                                    ym(YandexMetrikaEnums.id, 'reachGoal', 'WhatsApp');
                                }}>
                                <WhatsappSVG className="package-section-contact-me-social-icon package-social-button-with-pulse-animation" />
                            </a>
                            <a href={ContactDetailEnum.Telegram} target="_blank" rel="noopener noreferrer"
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'User',
                                        action: `Telegram -> Пакет ${type}`
                                    });
                                    ym(YandexMetrikaEnums.id, 'reachGoal', 'Telegram');
                                }}>
                                <TelegramSVG className="package-section-contact-me-social-icon package-social-button-with-pulse-animation" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PackageOverview;