import React, { useEffect } from 'react';
import AboutOlga from '../components/landingPageSections/aboutOlgaSection/AboutOlga';
import HowAccompanyingWorks from '../components/landingPageSections/HowAccompanyingWorks';
import OurPackages from '../components/landingPageSections/packagesSection/OurPackages';
import OurClients from '../components/landingPageSections/ourClientsSection/OurClients';
import ReviewsFromClients from '../components/landingPageSections/reviewsFromClientsSection/ReviewsFromClients';
import ContactMe from '../components/landingPageSections/contactMeSection/ContactMe';
import QuestionAnswer from '../components/frequentlyAskedQuestionsPage/questionAnswerSection/QuestionAnswer';
import InterestingArticles from '../components/landingPageSections/interestingArticlesSection/InterestingArticles';
import ReactGA from 'react-ga4';

import SiteMetaPropertyEnum from '../data/enums/SiteMetaPropertiesEnum';
import useTitleAndOgTitleMetaTag from '../components/UseTitleAndOgTitleMetaTag';
import WorldMapChart from '../components/worldMapChart/WorldMapChart';
import TechnicalFooter from '../components/technicalFooterSection/TechnicalFooter';
import UseDescriptionAndOgDescriptionMetaTag from '../components/UseDescriptionAndOgDescriptionMetaTag';
import UseOgUrlMetaTag from '../components/UseOgUrlMetaTag';
import "./HomePageStyles.css"
import UseOgTypeMetaTag from '../components/UseOgTypeMetaTag';
import UseCanonicalLinkTag from '../components/UseCanonicalLinkTag';
import CustomContactForm from '../components/contactForm/CustomContactForm';
import TopSectionBrief from '../components/topSectionBrief/TopSectionBrief';
import ImageWithContactForm from '../components/contactForm/ImageWithContactForm';
import RandomPosts from '../components/blog/RandomPosts';
import MyServices from '../components/landingPageSections/myServicesSection/MyServices';
import { Link } from 'react-router-dom';

interface HomePageProps {
    forwardedRefAboutMe: React.RefObject<HTMLDivElement>;
    forwardedRefPackages: React.RefObject<HTMLDivElement>;
    forwardedRefMyServices: React.RefObject<HTMLDivElement>;
    forwardedRefOurClients: React.RefObject<HTMLDivElement>;
    forwardedRefHowAccompanying: React.RefObject<HTMLDivElement>;
    forwardedRefReviewsFromClients: React.RefObject<HTMLDivElement>;
    forwardedRefQuestionAnswer: React.RefObject<HTMLDivElement>;
}

const HomePage: React.FC<HomePageProps> = (props) => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    useTitleAndOgTitleMetaTag(SiteMetaPropertyEnum.HomePageSiteTitle, SiteMetaPropertyEnum.HomePageSiteTitle);

    UseDescriptionAndOgDescriptionMetaTag(SiteMetaPropertyEnum.HomeSiteDescription, SiteMetaPropertyEnum.HomeSiteDescription);

    UseOgUrlMetaTag(SiteMetaPropertyEnum.URLPlainSite);
    UseOgTypeMetaTag(SiteMetaPropertyEnum.OgTypeWebsite);
    UseCanonicalLinkTag(SiteMetaPropertyEnum.URLPlainSite)

    const arrayOfPostUrls: string[] = [
        "argentina-citizenship-after-giving-birth",
        "give-birth-in-argentina-how-much-budget",
        "documents-to-bring",
        "parents-documents-explained"
    ];

    return (
        <div itemScope itemType="https://schema.org/WebSite">
            <meta itemProp="url" content="https://www.rodysolgoyargentina.com/" />
            <meta itemProp="name" content="Роды в Аргентине с Ольгой" />
            <meta itemProp="alternateName" content="Роды с Ольгой в Аргентине" />


            <TopSectionBrief />

            <div ref={props.forwardedRefAboutMe}>
                <AboutOlga />
            </div>

            <div ref={props.forwardedRefPackages}>
                <br />
                <br />
                <div>Помогу <Link to="/birth/">организовать</Link> самые заботливые роды в Аргентине </div>
                <OurPackages />
            </div>
            <div>
                <ContactMe />
            </div>
            <div ref={props.forwardedRefMyServices}>
                <br />
                <br />
                <div>Также я могу помочь Вам в рамках <Link to="/my-services/">разовых сопровождений</Link> по вопросам указанных ниже.</div>
                <h2 className="packageSectionHeading" style={{ fontSize: "26px!important" }}>Мои Услуги</h2>
                <MyServices />
            </div>
            <div ref={props.forwardedRefOurClients}>
                <OurClients />
            </div>
            {/* <div>
                <ImageWithContactForm
                    headerText={'Роды в Аргентине - больше возможностей и спокойствия для вашей семьи'}
                    indexOfImage={3} />
            </div> */}
            <div ref={props.forwardedRefHowAccompanying}>
                <HowAccompanyingWorks />
            </div>
            <div ref={props.forwardedRefReviewsFromClients}>
                <ReviewsFromClients /></div>
            {/* <div>
                <ImageWithContactForm
                    headerText={'Роды в Аргентине - уверенное будущее вашего ребенка'}
                    indexOfImage={4} />
            </div> */}
            <div>
                <section>
                    <h2 className='interesting-articles-section-heading'>Важно знать</h2>
                    <RandomPosts isFilteringCurrentArticle={false} currentArticleId={''}
                        arrayOfSpecificPostURLs={arrayOfPostUrls} />
                </section>
            </div>
            <div>
                <WorldMapChart />
            </div>

            <div>
                <InterestingArticles excludeArrayOfSpecificPostURLs={arrayOfPostUrls} />
            </div>

        </div >
    );
};

export default HomePage;