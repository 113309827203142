import ScrollAnimation from "../ScrollAnimation";
import CustomContactForm from "../contactForm/CustomContactForm"
import "./TopSectionBriefStyles.css"

const TopSectionBrief: React.FC = () => {
    return (

        <section className="top-about-brief-section">
            <div className='top-about-form-container'>
                <h1 className="site-main-heading">Роды в Аргентине с Ольгой</h1>
                <p>Самое заботливое сопровождение родов</p>

                <ul className='premium-list-item-container'>


                    <li className='premium-list-item'><span className="bullet">•</span><span className="bullet-text">Гражданство Аргентины для ребенка</span></li>

                    <li className='premium-list-item'><span className="bullet">•</span><span className="bullet-text">Комфортные роды в престижных клиниках столицы Аргентины</span></li>

                    <li className='premium-list-item'><span className="bullet">•</span><span className="bullet-text">Помощь с оформлением документов для родителей</span></li>

                    <li className='premium-list-item'><span className="bullet">•</span><span className="bullet-text">Более 100 успешных сопровождений родов разной сложности</span></li>

                    <li className='premium-list-item'><span className="bullet">•</span><span className="bullet-text">30 лет в Медицине, 20 лет в Аргентине</span></li>
                </ul>
            </div>
            {/* <div className="top-section-contact-form-container">
                <ScrollAnimation direction={'down'} displayedThreshold={0.2} customClassName='replace-slide-animation'>
                    <CustomContactForm />
                </ScrollAnimation>
            </div> */}
        </section>
    );
};

export default TopSectionBrief;