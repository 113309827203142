import './App.css';
import './SlidingMenuStyles.css'
import { Route, Routes, Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import BlogArticleFull from './components/blog/BlogArticleFull';
import BlogArticlesArrayData from './blogPosts/blogArticlesArrayData';
import { FaWhatsapp } from "react-icons/fa";
import { LiaTelegramPlane } from "react-icons/lia";
import { FaInstagram } from "react-icons/fa";
import ToggleContactButton from './components/ToggleContactButton';
import ContactDetailEnum from './data/enums/ContactDetailsEnum';
import { useEffect, useRef, useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import React from 'react';
import NotFoundPage from './pages/NotFoundPage';
import { ReactComponent as SiteLogoSVG } from './assets/temp/site_logo_cropped.svg';
import ReactGA from 'react-ga4';
import GoogleAnalyticsEnum from './data/enums/GoogleAnalyticsEnum';
import TechnicalFooter from './components/technicalFooterSection/TechnicalFooter';
import YandexMetrikaEnums from './data/enums/YandexMetrikaEnum';
import FaqPage from './components/frequentlyAskedQuestionsPage/faqPage';
import OurPackagesPage from './components/ourPackagesPage/OurPackagesPage';
import MyServicesPage from './components/myServicesPage.tsx/MyServicesPage';

function App() {

  const sectionRefAboutMe = useRef<HTMLDivElement>(null);
  const sectionRefPackages = useRef<HTMLDivElement>(null);
  const sectionRefMyServices = useRef<HTMLDivElement>(null);
  const sectionRefOurClients = useRef<HTMLDivElement>(null);
  const sectionRefHowAccompanying = useRef<HTMLDivElement>(null);
  const sectionRefReviewsFromClients = useRef<HTMLDivElement>(null);
  const sectionRefQuestionAnswer = useRef<HTMLDivElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();

  ReactGA.initialize(GoogleAnalyticsEnum.Mock);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 971) {
        setIsMenuOpen(false)
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>, isNotSmooth: boolean = false) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - (windowWidth <= 830 ? 20 : 80),
        behavior: isNotSmooth ? 'auto' : 'smooth',
      });
    }
  };

  const handleClick = (ref: React.RefObject<HTMLDivElement>) => {
    // Check if on home page or blog page
    if (isMenuOpen) {
      setIsMenuOpen(false)
    }
    if (window.location.pathname.includes('/blog') || window.location.pathname.includes('/frequent-questions')) {
      // Navigate to home page and then scroll
      setTimeout(() => { // wait for navigation to complete
        scrollToRef(ref, true);
      }, 50);
      navigate('/');
    } else {
      scrollToRef(ref);
    }
  };


  useEffect(() => {
    if (window.location.hash.includes('#reviews')) {
      // console.log(`reviews hash ${window.location.pathname}`)
      // Navigate to home page and then scroll
      // navigate('/');
      setTimeout(() => { // wait for navigation to complete
        scrollToRef(sectionRefReviewsFromClients);
      }, 1200);
    } else if
      (window.location.hash.includes('#accompanying')) {
      // console.log(`accompanying hash ${window.location.pathname}`)
      // Navigate to home page and then scroll
      // navigate('/');
      setTimeout(() => { // wait for navigation to complete
        scrollToRef(sectionRefHowAccompanying);
      }, 1200);
    }
    else if (window.location.hash.includes('#packages')) {
      // console.log(`packages hash ${window.location.pathname}`)
      // Navigate to home page and then scroll
      // navigate('/');
      setTimeout(() => { // wait for navigation to complete
        scrollToRef(sectionRefPackages);
      }, 1200);
    }
  }, []);


  return (
    <div className="App">
      <nav className={`topNav ${isMenuOpen ? "topNavMobile" : ""} `}>
        <div className="leftNav">
          {/* <img src={require('./assets/temp/temp_logo_cropped.png')} className='packageImage premium-package-logo-filtered-color'
            style={{ width: "140px", marginBottom: "0px" }} />
            <svg /> */}
          <Link to="/"
            onClick={() => {
              ReactGA.event({
                category: 'User',
                action: `Лого сайта клик`
              });
            }}>
            <SiteLogoSVG style={{
              width: "150px",
              height: "150px",
              padding: "3px 0px"
            }} className='svg-element'
              role='img' /></Link>

          <div>
            {/* <img src={require('./assets/temp/hands_logo.png')} className='packageImage premium-package-logo-filtered-color'
              style={{ width: "140px", marginBottom: "0px" }} />
            <li className='siteNameButton' data-testid="site-name-button"><Link to="/">Роды с Ольгой</Link></li>
            <div className='siteTagLine'>Сопровождение родов в Аргентине</div> */}
          </div>

          {/* <div className='siteLogo'><LuBaby /></div> */}

        </div>
        <div className='burger-menu-social-links-container' style={{
          alignItems: windowWidth <= 971 && isMenuOpen ? "flex-end" : "",
        }}>

          {windowWidth < 971 && <div onClick={toggleMenu}><GiHamburgerMenu className={`burger-menu-button burger-menu-button-open`}
            style={{
              visibility: isMenuOpen ? "hidden" : "initial"
            }}
          /></div>}
          {/* {windowWidth < 971 && isMenuOpen && <div onClick={toggleMenu}
            style={{ zIndex: 5 }}><IoCloseSharp className='burger-menu-button burger-menu-button-closed' /></div>} */}

          <div className={`sliding-menu ${isMenuOpen ? 'open' : ''}`}>
            <div style={{
              justifyContent: "center",
              marginTop: "10px"
            }}
              className={`rightNav ${isMenuOpen ? "social-container-for-menu-open" : ""}`}
            >
              <a onClick={toggleMenu}
                style={{ zIndex: 5, paddingBottom: "0px!important", display: "flex", marginRight: "25px" }}><IoCloseSharp className='burger-menu-button burger-menu-button-closed' /></a>
              <a href={ContactDetailEnum.Instagram} target="_blank" rel="noopener noreferrer"
                data-testid="top-menu-contact-instagram">
                <FaInstagram className="topSvgLogo"
                  onClick={() => {
                    ReactGA.event({
                      category: 'User',
                      action: `Instagram -> Топ`
                    });
                    ym(YandexMetrikaEnums.id, 'reachGoal', 'Instagram');
                  }} />
              </a>
              <a href={ContactDetailEnum.WhatsApp} target="_blank" rel="noopener noreferrer"
                data-testid="top-menu-contact-whatsapp">
                <FaWhatsapp className="topSvgLogo"
                  onClick={() => {
                    ReactGA.event({
                      category: 'User',
                      action: `WhatsApp -> Топ`
                    });
                    ym(YandexMetrikaEnums.id, 'reachGoal', 'WhatsApp');
                  }} />
              </a>
              <a href={ContactDetailEnum.Telegram} target="_blank" rel="noopener noreferrer"
                data-testid="top-menu-contact-telegram">
                <LiaTelegramPlane className="topSvgLogo topTelegramLogo" href=''
                  onClick={() => {
                    ReactGA.event({
                      category: 'User',
                      action: `Telegram -> Топ`
                    });
                    ym(YandexMetrikaEnums.id, 'reachGoal', 'Telegram');
                  }}
                />
              </a>
            </div>
            <nav className="nav-section">
              <ul className="centerNav" style={{ marginTop: "50px" }}>

                <li><NavLink to="/" onClick={() => {
                  handleClick(sectionRefAboutMe);
                  ReactGA.event({
                    category: 'User',
                    action: `Моб Меню "Обо мне"`
                  });
                }
                }>Обо мне</NavLink></li>
                {/* <li><NavLink to="/" onClick={() => {
                  handleClick(sectionRefHowAccompanying);
                  ReactGA.event({
                    category: 'User',
                    action: `Моб Меню "Сопровождение"`
                  });
                }}>Cопровождение</NavLink></li> */}

                <li><NavLink to="/" onClick={() => {
                  handleClick(sectionRefPackages);
                  ReactGA.event({
                    category: 'User',
                    action: `Моб Меню "Роды Вашей мечты"`
                  });
                }}>Роды Вашей мечты</NavLink></li>

                <li><NavLink to="/" onClick={() => {
                  handleClick(sectionRefMyServices);
                  ReactGA.event({
                    category: 'User',
                    action: `Моб Меню "Мои услуги"`
                  });
                }}>Мои услуги</NavLink></li>

                <li><NavLink to="/frequent-questions/" onClick={() => {
                  setIsMenuOpen(false);
                  ReactGA.event({
                    category: 'User',
                    action: `Моб Меню "Частые вопросы"`
                  });

                }}>Частые вопросы</NavLink></li>

                <li><NavLink to="/" onClick={() => {
                  handleClick(sectionRefOurClients);
                  ReactGA.event({
                    category: 'User',
                    action: `Моб Меню "Клиенты"`
                  });

                }}>Клиенты</NavLink></li>

                <li><NavLink to="/" onClick={() => {
                  handleClick(sectionRefReviewsFromClients);
                  ReactGA.event({
                    category: 'User',
                    action: `Моб Меню "Отзывы"`
                  });
                }}>Отзывы</NavLink></li>

                {/* <li><NavLink to="/" onClick={() => handleClick(sectionRefQuestionAnswer)}>Вопрос-ответ</NavLink></li> */}

                <li><Link to="/blog/" onClick={() => {
                  setIsMenuOpen(false);
                  ReactGA.event({
                    category: 'User',
                    action: `Моб Меню "Блог"`
                  });
                }}>Блог</Link></li>
              </ul>
            </nav>
          </div>

          {/* desktop menu */}
          {windowWidth > 970 && (
            <ul className="centerNav">
              <div className="center-nav-sub-nav">
                <li><NavLink to="/" onClick={() => {
                  handleClick(sectionRefAboutMe);
                  ReactGA.event({
                    category: 'User',
                    action: `Меню "Обо Мне"`
                  });
                }}>Обо мне</NavLink></li>
                {/* <li><NavLink to="/" onClick={() => {
                  handleClick(sectionRefHowAccompanying);
                  ReactGA.event({
                    category: 'User',
                    action: `Меню "Сопровождение"`
                  });
                }}>Cопровождение</NavLink></li> */}
                <li style={{ width: "107px" }}><NavLink to="/"
                  onClick={() => {
                    handleClick(sectionRefPackages)
                    ReactGA.event({
                      category: 'User',
                      action: `Меню "Роды Вашей мечты"`
                    });
                  }}>Роды Вашей мечты</NavLink></li>

                <li style={{ width: "107px" }}><NavLink to="/"
                  onClick={() => {
                    handleClick(sectionRefMyServices)
                    ReactGA.event({
                      category: 'User',
                      action: `Меню "Мои услуги"`
                    });
                  }}>Мои услуги</NavLink></li>

              </div>
              <li style={{ width: "107px" }}><NavLink to="/frequent-questions/"
                onClick={() => {
                  setIsMenuOpen(false);
                  ReactGA.event({
                    category: 'User',
                    action: `Меню "Частые вопросы"`
                  });
                }}>Частые вопросы</NavLink></li>
              <li><NavLink to="/" onClick={() => {
                handleClick(sectionRefOurClients);
                ReactGA.event({
                  category: 'User',
                  action: `Меню "Клиенты"`
                });
              }}>Клиенты</NavLink></li>
              <li><NavLink to="/" onClick={() => {
                handleClick(sectionRefReviewsFromClients);
                ReactGA.event({
                  category: 'User',
                  action: `Меню "Отзывы"`
                });
              }}>Отзывы</NavLink></li>
              {/* <li><NavLink to="/" onClick={() => handleClick(sectionRefQuestionAnswer)}>Вопрос-ответ</NavLink></li> */}
              <li><Link to="/blog/" onClick={() => {
                setIsMenuOpen(false);
                ReactGA.event({
                  category: 'User',
                  action: `Меню "Блог"`
                });
              }}>Блог</Link></li>
            </ul>)}

          {windowWidth > 970 && (<div style={{
            // flexDirection: windowWidth <= 830 && isMenuOpen ? "column" : "row",
            alignSelf: windowWidth <= 830 && isMenuOpen ? "center" : "",
          }}
            className={`rightNav ${isMenuOpen ? "social-container-for-menu-open" : ""}`}
          >
            <a href={ContactDetailEnum.Instagram} target="_blank" rel="noopener noreferrer"
              data-testid="top-menu-contact-instagram">
              <FaInstagram className="topSvgLogo"
                onClick={() => {
                  ReactGA.event({
                    category: 'User',
                    action: `Instagram -> Топ`
                  });
                  ym(YandexMetrikaEnums.id, 'reachGoal', 'Instagram');
                }} />
            </a>
            <a href={ContactDetailEnum.WhatsApp} target="_blank" rel="noopener noreferrer"
              data-testid="top-menu-contact-whatsapp">
              <FaWhatsapp className="topSvgLogo"
                onClick={() => {
                  ReactGA.event({
                    category: 'User',
                    action: `WhatsApp -> Топ`
                  });
                  ym(YandexMetrikaEnums.id, 'reachGoal', 'WhatsApp');
                }} />
            </a>
            <a href={ContactDetailEnum.Telegram} target="_blank" rel="noopener noreferrer"
              data-testid="top-menu-contact-telegram">
              <LiaTelegramPlane className="topSvgLogo topTelegramLogo" href=''
                onClick={() => {
                  ReactGA.event({
                    category: 'User',
                    action: `Telegram -> Топ`
                  });
                  ym(YandexMetrikaEnums.id, 'reachGoal', 'Telegram');
                }}
              />
            </a>
          </div>)}
        </div>
      </nav>

      <ToggleContactButton />

      <Routes>
        <Route path="/" element={<HomePage
          forwardedRefAboutMe={sectionRefAboutMe}
          forwardedRefPackages={sectionRefPackages}
          forwardedRefOurClients={sectionRefOurClients}
          forwardedRefMyServices={sectionRefMyServices}
          forwardedRefHowAccompanying={sectionRefHowAccompanying}
          forwardedRefReviewsFromClients={sectionRefReviewsFromClients}
          forwardedRefQuestionAnswer={sectionRefQuestionAnswer} />} />
        <Route path="/blog/" element={<BlogPage />} />
        <Route path="/blog/:id/*" element={<BlogArticleFull blogArticles={BlogArticlesArrayData} />} />
        <Route path="/frequent-questions/" element={<FaqPage />} />
        <Route path="/birth/" element={<OurPackagesPage />} />
        <Route path="/my-services/" element={<MyServicesPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <TechnicalFooter />
    </div>
  );
}

export default App;
