import React from 'react';
import './HowAccompanyingWorksStyles.css';
import { LiaFileContractSolid } from "react-icons/lia";
import { LiaHospital } from "react-icons/lia";
import { TiGroup } from "react-icons/ti";
import { BiIdCard } from "react-icons/bi";
import ImageSpinnerWrapper from '../CustomImageWithSpinner';
import { MdLocalAirport, MdOutlineContactPhone } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { MdQuestionMark } from "react-icons/md";
import { IoMdBoat } from "react-icons/io";
import { LuBaby } from "react-icons/lu";
import { TbEmergencyBed } from "react-icons/tb";
import { FaRegFaceGrinStars } from "react-icons/fa6";
import ScrollAnimation from '../ScrollAnimation';

const HowAccompanyingWorks: React.FC = () => {
    return (
        <section className='howAccompanyingWorksSection'>
            <h2 className='how-accompanying-heading'>Как проходит cопровождение</h2>
            {/* <h3 className='how-accompanying-heading-2'>Свяжитесь со мной для консультации и потом</h3> */}

            <ScrollAnimation direction={'left'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-left'>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>

                        <div className='how-accompanying-sub-section-number ha-number-left'>1<MdOutlineContactPhone className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                    <div className='how-accompanying-sub-section-text-block'>

                        Напрямую свяжитесь со мной для консультации. Отвечу на Ваши вопросы о родах в Аргентине со мной.
                    </div>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'right'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-right'>

                    <div className='how-accompanying-sub-section-text-block'>


                        Сотрудничество начинается сразу после того, как Вы приняли решение о родах в Аргентине и Выбрали нас. Вы можете задавать абсолютно любые вопросы. Мы поможем подобрать авиабилеты, собрать чемодан и сумку для ребенка. Подскажем, какие вещи привезти с собой, а какие можно купить на месте. Поможем с подбором отеля на первое время. Я и мои помощники будут всегда на связи с Вами.
                    </div>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-right'>2<LiaFileContractSolid className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'left'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-left'>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-left'>3<MdLocalAirport className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                    <div className='how-accompanying-sub-section-text-block'>

                        В день прилета мы встретим Вас в аэропорту вместе с нашим водителем для сопровождения в отель, привезем необходимые вещи на первое время, такие как сим-карты и переходники.
                    </div>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'right'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-right'>

                    <div className='how-accompanying-sub-section-text-block'>

                        Почти сразу после приезда, мы проведем знакомство с  врачами и покажем клиники, которые Вас заинтересуют. Мы порекомендуем риелторов для быстрого поиска квартиры. Проведем адаптационную встречу, где расскажем обо всех тонкостях жизни в Аргентине и ответим на все Ваши вопросы.
                    </div>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-right'>4<LiaHospital className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'left'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-left'>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-left'>5<FaUserDoctor className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                    <div className='how-accompanying-sub-section-text-block'>

                        На первом приеме ваш доктор назначит Вам все необходимые анализы и обследования, на которые я Вас сопровожу. На все посещения и обследования я возьму талончик на удобное для Вас время.
                    </div>

                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'right'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-right'>

                    <div className='how-accompanying-sub-section-text-block '>

                        Мы остаемся с Вами на связи 24/7. Вы можете задать нам абсолютно любой вопрос. Где купить какао, проблемы со здоровьем, бытовой вопрос - мы поможем. Магазины для малышей, продуктовые магазины, магазины одежды, техники, канцтоваров - мы поможем Вам с любым вопросом.
                    </div>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-right'>6<MdQuestionMark className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'left'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-left'>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-left'>7<IoMdBoat className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                    <div className='how-accompanying-sub-section-text-block'>

                        Мы также поможем организовать экскурсии по Буэнос-Айресу, в Тигре и Люхан, если Вы не хотите скучать в ожидании дня родов.
                    </div>

                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'right'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-right'>

                    <div className='how-accompanying-sub-section-text-block'>

                        В момент “Х” я буду с Вами с самого начала и до перевода в послеродовую палату. Я также буду все время на связи с вашим врачом и/или акушеркой.
                    </div>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-right'>8<TbEmergencyBed className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'left'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-left'>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-left'>9<LuBaby className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                    <div className='how-accompanying-sub-section-text-block'>

                        После рождения малыша наше сотрудничество не заканчивается. Я сопровожу Вас на прием к неонатологу для осмотра малыша и к врачу для послеродового осмотра мамы.
                    </div>

                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'right'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-right'>

                    <div className='how-accompanying-sub-section-text-block'>

                        Поможем оформить документы на ребенка: свидетельство о рождении, DNI и паспорт. Также сможем помочь с легализацией и получением DNI для Вас, если решите остаться в Аргентине.
                    </div>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-right' style={{ right: "-50px" }}>10<BiIdCard className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'down'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                <div className='how-accompanying-sub-section ha-border-left'>
                    <ScrollAnimation direction={'up'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                        <div className='how-accompanying-sub-section-number ha-number-left'>11<FaRegFaceGrinStars className='how-accompanying-sub-section-icon' /></div>
                    </ScrollAnimation>
                    <div className='how-accompanying-sub-section-text-block'>

                        Со мной и моей командой Вы никогда не будете чувствовать себя одиноко и неуверенно в чужой стране.
                    </div>

                </div>
            </ScrollAnimation>



        </section>
    );
};

export default HowAccompanyingWorks;